import { posthogCaptureWithUserContext } from "./general";
import {
  getBrowseCoursesFilterPropertiesForEvent,
  getCoursePropertiesForEvent,
} from "./properties";

/**
 * Captures the event the user clicks the opt-in banner link and opens the new courses page.
 *
 * @param {{ linkUrl: string }} properties
 */
export function posthogEventCoursesOptInBannerClicked({ linkUrl }) {
  posthogCaptureWithUserContext("courses_opt_in_banner_clicked", {
    link_url: linkUrl,
  });
}

/**
 * Captures the event the opt-in banner is closed without opting in.
 */
export function posthogEventCoursesOptInBannerClosed() {
  posthogCaptureWithUserContext("courses_opt_in_banner_closed");
}

/**
 * Captures the event the user clicks the opt-out banner link and reverts to the old courses page.
 *
 * @param {{ linkUrl: string }} properties
 */
export function posthogEventCoursesOptOutBannerClicked({ linkUrl }) {
  posthogCaptureWithUserContext("courses_opt_out_banner_clicked", {
    link_url: linkUrl,
  });
}

/**
 * Captures the event the opt-out banner is closed without opting out.
 */
export function posthogEventCoursesOptOutBannerClosed() {
  posthogCaptureWithUserContext("courses_opt_out_banner_closed");
}

/**
 * Captures the event the user selects a course to view on the
 * explorer page.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {Object} [properties.browseCoursesContext]
 * @param {number} properties.browseCoursesContext.count
 * @param {{ text: string, publisher: string[], type: string[] }} properties.browseCoursesContext.filters
 * @param {number} properties.browseCoursesContext.positionSelected
 * @param {Object} [properties.viewCoursesContext]
 * @param {boolean} properties.viewCoursesContext.groupExpanded
 * @param {number} properties.viewCoursesContext.groupPosition
 * @param {"class" | "school" | "popular" | "recent"} properties.viewCoursesContext.groupType
 * @param {number} properties.viewCoursesContext.positionInGroup
 */
export function posthogEventCourseViewed({
  triggerId,
  course = {},
  browseCoursesContext,
  viewCoursesContext,
}) {
  const browseCoursesProperties = browseCoursesContext
    ? {
        ...getBrowseCoursesFilterPropertiesForEvent(
          browseCoursesContext.count,
          browseCoursesContext.filters,
        ),
        browse_courses_list_position: browseCoursesContext.positionSelected,
      }
    : {};

  const viewCoursesProperties = viewCoursesContext
    ? {
        view_courses_group_expanded: viewCoursesContext.groupExpanded,
        view_courses_group_position: viewCoursesContext.groupPosition,
        view_courses_group_type: viewCoursesContext.groupType,
        view_courses_position_in_group: viewCoursesContext.positionInGroup,
      }
    : {};

  posthogCaptureWithUserContext("course_viewed", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    ...browseCoursesProperties,
    ...viewCoursesProperties,
  });
}

/**
 * Captures the event the user adds a course to their school
 * allocation.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 */
export function posthogEventCourseAdded({ triggerId, course = {} }) {
  posthogCaptureWithUserContext("course_added", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    // Currently only a "school" can add a course to their selection
    entityType: "school",
  });
}

/**
 * Captures the event the user removes a course from their
 * school allocation.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 */
export function posthogEventCourseRemoved({ triggerId, course = {} }) {
  posthogCaptureWithUserContext("course_removed", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    // Currently only a "school" can remove a course from their selection
    entityType: "school",
  });
}

/**
 * Captures the event the user copies a course to their own
 * school as a draft.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {number} properties.copiedCourseId
 * @param {string} properties.entityType - "school" or "trust"
 */
export function posthogEventCourseCopied({
  triggerId,
  course = {},
  copiedCourseId,
  entityType,
}) {
  posthogCaptureWithUserContext("course_copied", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    copied_course_id: copiedCourseId,
    entity_type: entityType,
  });
}

/**
 * Captures the event the user modifies the class allocation
 * of a course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {number} properties.numberOfClassesBefore
 * @param {number} properties.numberOfClassesAfter
 */
export function posthogEventCourseClassAllocationModified({
  triggerId,
  course = {},
  numberOfClassesBefore,
  numberOfClassesAfter,
}) {
  posthogCaptureWithUserContext("course_class_allocation_modified", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    number_of_classes_before: numberOfClassesBefore,
    number_of_classes_after: numberOfClassesAfter,
  });
}

/**
 * Captures the event the user creates a brand new course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {string} properties.entityType - "school" or "trust"
 */
export function posthogEventCourseCreated({ triggerId, course = {}, entityType }) {
  posthogCaptureWithUserContext("course_created", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    entity_type: entityType,
  });
}

/**
 * Captures the event the user deletes a course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {string} properties.entityType - "school" or "trust"
 */
export function posthogEventCourseDeleted({ triggerId, course = {}, entityType }) {
  posthogCaptureWithUserContext("course_deleted", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    entity_type: entityType,
  });
}

/**
 * Captures the event the user starts editing an existing
 * course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {string} properties.entityType - "school" or "trust"
 */
export function posthogEventCourseEditingStarted({ triggerId, course = {}, entityType }) {
  posthogCaptureWithUserContext("course_editing_started", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    entity_type: entityType,
  });
}

/**
 * Captures the event the user publishes a course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {string} properties.entityType - "school" or "trust"
 */
export function posthogEventCoursePublished({ triggerId, course = {}, entityType }) {
  posthogCaptureWithUserContext("course_published", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    entity_type: entityType,
  });
}

/**
 * Captures the event the user unpublishes a course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 * @param {string} properties.entityType - "school" or "trust"
 */
export function posthogEventCourseUnpublished({ triggerId, course = {}, entityType }) {
  posthogCaptureWithUserContext("course_unpublished", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
    entity_type: entityType,
  });
}

/**
 * Captures the event the user leaves the browse courses page
 * without selecting a course.
 *
 * @param {Object} properties
 * @param {number} properties.count
 * @param {{ text: string, publisher: string[], type: string[] }} properties.filters
 */
export function posthogEventBrowseCoursesLeftWithoutSelection({
  count,
  filters = {},
}) {
  posthogCaptureWithUserContext("browse_courses_left_without_selection", {
    ...getBrowseCoursesFilterPropertiesForEvent(count, filters),
  });
}

/**
 * Captures the event the user clicks a link to the courses
 * home page.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {string} properties.linkUrl
 */
export function posthogEventCoursesHomeClicked({ triggerId, linkUrl }) {
  posthogCaptureWithUserContext("courses_home_clicked", {
    trigger_id: triggerId,
    link_url: linkUrl,
  });
}

/**
 * Captures the event the user clicks a link to manage a
 * specific course.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 * @param {Parameters<typeof getCoursePropertiesForEvent>[0]} properties.course
 */
export function posthogEventCourseManageClicked({ triggerId, course = {} }) {
  posthogCaptureWithUserContext("course_manage_clicked", {
    trigger_id: triggerId,
    ...getCoursePropertiesForEvent(course),
  });
}

/**
 * Captures the event the user clicks the link in the tip
 * about copying an existing course
 *
 * @param {Object} properties
 * @param {string} properties.triggerId
 */
export function posthogEventCourseCopyTipClicked({ triggerId }) {
  posthogCaptureWithUserContext("course_copy_tip_clicked", {
    trigger_id: triggerId,
  });
}
